import { Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomInput from "../../../components/UI/CustomInput";
import { useSelector } from "react-redux";

export default function DepositAndWithdraw({
  isWithdraw,
  payload,
  setPayload,
  balance,
  myAvailableBalance,
  userName,
}) {
  const userData = useSelector((state) => state.account.userData);
  console.log("userData", userData);
  return (
    <Grid gap={1} fontSize="sm" templateColumns="repeat(3,1fr)">
      <GridItem>
        <Text>{userData?.username}</Text>
      </GridItem>
      <GridItem>
        <CustomInput value={userData?.balance} />
      </GridItem>
      <GridItem>
        <CustomInput
          value={Math.abs(
            payload?.amount
              ? isWithdraw
                ? userData?.balance + payload?.amount
                : userData?.balance - payload?.amount
              : 0
          )}
        />
      </GridItem>
      <GridItem>
        <Text>{payload?.user?.username}</Text>
      </GridItem>
      <GridItem>
        <CustomInput value={payload?.user?.balance} />
      </GridItem>
      <GridItem>
        {!userName && (
          <CustomInput
            max={myAvailableBalance}
            value={Math.abs(
              payload?.amount
                ? isWithdraw
                  ? payload?.user?.balance - payload?.amount
                  : payload?.user?.balance + payload?.amount
                : 0
            )}
          />
        )}
      </GridItem>
      <GridItem>
        <Text>Amount</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <CustomInput
          onChange={(e) => {
            setPayload({
              ...payload,
              amount: parseInt(e.target.value),
            });
          }}
        />
      </GridItem>
      <GridItem>
        <Text>Remark</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <CustomInput
          onChange={(e) => {
            setPayload({
              ...payload,
              remarks: e.target.value,
            });
          }}
        />
      </GridItem>
      <GridItem>
        <Text>Transaction Password</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <CustomInput
          type="password"
          value={payload?.transactionPassword}
          onChange={(e) =>
            setPayload({ ...payload, transactionPassword: e.target.value })
          }
        />
      </GridItem>
    </Grid>
  );
}
