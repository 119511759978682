import { Box, Button, Grid, GridItem, Text, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import "./game.css";
import "./all.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  betAndFancyLocksCricketAPI,
  getAdminBookMakerCricketAPI,
  getAdminPlExposureCricketAPI,
  getAdminUserBookCricketAPI,
  getBetActiveDeactiveAPI,
  getBetActiveDeactiveCricketAPI,
  getClientBetsCricketAPI,
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
} from "../../service/cricket";
import BetsLock from "./components/BetsLock";
import ViewMoreBet from "./components/ViewMoreBet";
import moment from "moment/moment";
import BookMakersBook from "./components/BookMakers";
import {
  betAndFancyLocksSoccerAPI,
  betAndFancyLocksTennisAPI,
  getAdminBookMakerSoccerAPI,
  getAdminBookMakerTennisAPI,
  getAdminUserBookSoccerAPI,
  getAdminUserBookTennisAPI,
} from "../../service/soccer&tennis";
import { getLiveStreamingUrlAPI } from "../../service/AuthService";
import Ladder from "./components/Ladder";

export default function Cricket() {
  const [gameData, setGameData] = useState({
    gameName: "Cricket",
    gameType: "Match",
    time: "Jan 02,2023 10:00 AM",
  });
  const toast = useToast();
  const [matchOdds, setMatchOdds] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const location = useLocation();
  const [showMarket, setShowMarket] = useState();
  const gameOdds = useSelector((state) => state.gameData);
  const gameDetails = useSelector((state) => state.gameData);
  const [isBooksModalOpen, setIsBookModalOpen] = useState(false);
  const [userBetStatus, setUserBetStatus] = useState(false);
  const [fancyBetStatus, setFancyBetStatus] = useState(false);
  const [isBetsLockModalOpen, setIsBetsLockModalOpen] = useState(false);
  const [isBookMakerOpen, setIsBookMakerOpen] = useState(false);
  const [isViewMoreBetModalOpen, setIsViewMoreBetModalOpen] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [isLiveTvOpen, setIsLiveTvOpen] = useState(false);
  const [gameSettings_, setGameSettings_] = useState({});
  const [everyRunnerExpousure, setEveryRunnerExpousure] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("book");
  const [userBook, setUserBook] = React.useState({});
  let items = location.pathname.split("/");
  var gameType = items[items.length - 2];
  var eventId = items[items.length - 1];
  var gameSettings = useSelector(
    (state) => state.gameData[gameType + "Settings"]
  );
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const getGameEventDetails = useCallback(async () => {
    const { response } = await getCricketEventDetailsAPI(eventId);
    setMatchDetails(response);
    return response;
  }, [eventId, gameType]);
  useEffect(() => {
    getGameEventDetails();
    setGameSettings_(gameSettings[eventId]);
  }, [location.pathname]);
  const getCricketPlExposure = async () => {
    const { response } = await getAdminPlExposureCricketAPI(eventId);
    if (response) {
      setMatchData(response);
    }
  };
  const getClientBets = async (betStatus_) => {
    const { response } = await getClientBetsCricketAPI(
      eventId,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setClientBets(response);
    }
  };
  const applyBlinkEffect = (element) => {
    element.classList.add("blink");

    setTimeout(() => {
      element.classList.remove("blink");
    }, 1000);
  };

  useEffect(() => {
    const layButtons = document.querySelectorAll(".box-w1");
    const observers = [];
    layButtons.forEach((layButton) => {
      const observer = new MutationObserver(() => {
        applyBlinkEffect(layButton);
      });
      observer.observe(layButton, {
        childList: true,
        subtree: true,
        characterData: true,
      });
      observers.push(observer);
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, [gameOdds]);
  const getBetActiveDeactive = async () => {
    const { response } = await getBetActiveDeactiveCricketAPI(eventId);
    if (response) {
      setUserBetStatus(response?.betLock);
      setFancyBetStatus(response?.fancyLock);
    }
  };
  const checkIfFloat = (value) => {
    return value % 1 != 0 ? parseFloat(value).toFixed(2) : parseInt(value);
  };
  const getBookMakerBook = async (type = "book") => {
    let data;
    if (type == "user") {
      if (gameType === "cricket") {
        const { response } = await getAdminUserBookCricketAPI(eventId);
        if (response) {
          data = response;
        }
      } else if (gameType === "soccer") {
        const { response } = await getAdminUserBookSoccerAPI(eventId);
        if (response) {
          data = response;
        }
      } else if (gameType === "tennis") {
        const { response } = await getAdminUserBookTennisAPI(eventId);
        if (response) {
          data = response;
        }
      }
    } else if (type == "book") {
      if (gameType === "cricket") {
        const { response } = await getAdminBookMakerCricketAPI(eventId);
        if (response) {
          data = response;
        }
      } else if (gameType === "soccer") {
        const { response } = await getAdminBookMakerSoccerAPI(eventId);
        if (response) {
          data = response;
        }
      } else if (gameType === "tennis") {
        const { response } = await getAdminBookMakerTennisAPI(eventId);
        if (response) {
          data = response;
        }
      }
    }
    if (data) {
      let rows_ = [];
      let columns_ = [];
      data.map((item) => {
        let row = {};
        row.userName = item?.userName;
        item?.runnerBookResponseDTOS?.map((match) => {
          row[match?.runnerName] = match?.pandL;
        });
        rows_.push(row);
      });
      if (rows_.length > 0) {
        columns_.push({ name: "User Name", id: "userName" });
        Object.keys(rows_[0]).map((key) => {
          if (key !== "userName") {
            columns_.push({ name: key, id: key });
          }
        });

        setUserBook({
          columns: columns_,
          rows: rows_,
        });
      }
    }
  };
  const getCricketOddsByMatchId = useCallback(async () => {
    const { response, code } = await getCricketOddsByMatchIdAPI(eventId);
    if (code != 200) {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      window.location.href = "/";
      return;
    }
    let response_ = {};
    response_[eventId] = response;
    setMatchOdds(response);
    dispatch({
      type: "gameData/setCricketOdds",
      payload: response_,
    });
  }, [eventId]);
  const getLadderForMarket = (marketId) => {
    return gameSettings?.plExposureResponseDTOs?.find(
      (i) => i.marketIdExternal === marketId
    )?.runnerPlExposureDTOS;
  };

  useEffect(() => {
    if (eventId) {
      getCricketPlExposure();
      getClientBets();
      let interval = setInterval(() => {
        getCricketPlExposure();
        getClientBets();
        getBetActiveDeactive();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [eventId]);
  useEffect(() => {
    let interval = setInterval(() => {
      getCricketOddsByMatchId();
    }, 3000);
    return () => clearInterval(interval);
  }, [eventId]);
  const betAndFancyLocks = async (name, lock) => {
    let payload = {
      cricketId: eventId,
    };
    if (name == "betlock") {
      payload.isBetLockedAll = lock;
    } else {
      payload.isFancyLockedALl = lock;
    }
    var response_;
    switch (gameType) {
      case "cricket":
        var { response } = await betAndFancyLocksCricketAPI(payload);
        response_ = response;
        break;
      case "soccer":
        var { response } = await betAndFancyLocksSoccerAPI(payload);
        response_ = response;
        break;
      case "tennis":
        var { response } = await betAndFancyLocksTennisAPI(payload);
        response_ = response;
        break;
    }

    if (response_) {
      toast({
        title: "Success",
        description: response_?.message,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      setMatchData(response_);
      getBetActiveDeactive();
    }
  };
  const getExposure = (match, marketId, marketName = "FANCY") => {
    let exposure_;
    if (marketName == "FANCY") {
      exposure_ = gameSettings?.plExposureResponseDTOs?.filter(
        (item) => item.marketIdExternal == match.marketId
      );

      if (exposure_ && exposure_.length > 0) return exposure_[0];
      else return null;
    } else {
      gameSettings?.plExposureResponseDTOs?.map((item) =>
        item?.runnerPlExposureDTOS?.map((event) => {
          if (
            event?.selectionId == match?.selectionId &&
            item?.marketIdExternal == marketId?.marketId
          ) {
            exposure_ = event;
          }
        })
      );
    }
    return exposure_;
  };
  const getCombinedExposureFromAll = (odds) => {
    let uniqueRunnerName = [];
    matchOdds?.matchOdds[0]?.oddDetailsDTOS?.map((item) => {
      if (!uniqueRunnerName.includes(item.runnerName)) {
        uniqueRunnerName.push(item.runnerName);
      }
    });

    matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS?.map((item) => {
      if (!uniqueRunnerName.includes(item.runnerName)) {
        uniqueRunnerName.push(item.runnerName);
      }
    });
    uniqueRunnerName = uniqueRunnerName.filter((item) => item != null);

    let exposure = 0;
    let combinedRunnerObject = {};
    uniqueRunnerName.map((item) => {
      let exposure_ = 0;
      let matchOddsExpousure = getExposure(
        matchOdds?.matchOdds[0]?.oddDetailsDTOS?.filter(
          (item_) => item_.runnerName == item
        )[0],
        matchOdds?.matchOdds[0],
        "MATCH_ODDS"
      );
      if (matchOddsExpousure) exposure_ += matchOddsExpousure?.exposure;
      let bookMakerOddsExpousure = getExposure(
        matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS?.filter(
          (item_) => item_.runnerName == item
        )[0],
        matchOdds?.bookMakerOdds[0]?.bm1,
        "MATCH_ODDS"
      );
      if (bookMakerOddsExpousure) exposure_ += bookMakerOddsExpousure?.exposure;
      combinedRunnerObject[item] = parseFloat(exposure_).toFixed(2);
      exposure += exposure_;
    });

    return combinedRunnerObject;
  };
  const getLiveStreamingUrl = async () => {
    let gameId = await getGameEventDetails();
    const { response } = await getLiveStreamingUrlAPI(gameId?.eventId);
    setChannelId(response);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [eventId]);
  useEffect(() => {
    getBookMakerBook(selectedTab);
  }, [selectedTab]);
  return (
    <div class="col-md-12 main-container">
      {isBookMakerOpen && (
        <BookMakersBook
          type={isBookMakerOpen}
          eventId={eventId}
          isOpen={isBookMakerOpen}
          onClose={() => setIsBookMakerOpen(false)}
          gameType={gameType}
        />
      )}

      {/* <Books
        eventId={eventId}
        gameType={gameType}
        onClose={() => setIsBookModalOpen(false)}
        isOpen={isBooksModalOpen}
      /> */}
      {isBetsLockModalOpen && (
        <BetsLock
          eventId={eventId}
          gameType={gameType}
          onClose={() => setIsBetsLockModalOpen(false)}
          isOpen={isBetsLockModalOpen}
        />
      )}
      <ViewMoreBet
        eventId={eventId}
        gameType={gameType}
        onClose={() => setIsViewMoreBetModalOpen(false)}
        isOpen={isViewMoreBetModalOpen}
        data={clientBets}
        getClientBets={getClientBets}
      />
      {showMarket && (
        <Ladder
          isOpen={showMarket}
          data={getLadderForMarket(showMarket)}
          onClose={() => setShowMarket(false)}
        />
      )}
      <div class="listing-grid">
        <div class="table-top-buttons row">
          <div class="col-md-6"></div>
          <div class="col-md-6 float-right text-right">
            <form>
              <ul class="d-inline-block">
                <li class="form-group active-button m-l-20 d-inline-block BetActives">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-back active"
                    id="bet_lock"
                  >
                    Bet Lock
                  </a>
                  <ul class="sub-button">
                    {/* <li>
                      <a
                        id="btn-bet_active_all"
                        data-status="0"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          betAndFancyLocks("betlock", !userBetStatus)
                        }
                      >
                        {userBetStatus ? "Deactive " : "All Active"}
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="javascript:void(0)"
                        id="btn-modal_userwisebetsttus"
                        onClick={() =>
                          setIsBetsLockModalOpen("betlockuserwise")
                        }
                      >
                        Userwise
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="form-group active-button m-l-20 d-inline-block FancyActive">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-back active"
                    id="btn-fancy_bet_lock"
                  >
                    Fancy Lock
                  </a>
                  <ul class="sub-button">
                    {/*  <li>
                      <a
                        id="btn-fancy_bet_active_all"
                        data-status="0"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          betAndFancyLocks("fancylock", !fancyBetStatus)
                        }
                      >
                        {fancyBetStatus ? "Deactive " : "All Active"}
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="javascript:void(0)"
                        id="btn-modal_userwisefencybetsttus"
                        onClick={() =>
                          setIsBetsLockModalOpen("fancylockuserwise")
                        }
                      >
                        Userwise
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="form-group active-button m-l-20 d-inline-block">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-back active"
                    id="modal-btn_userbook"
                    onClick={() => setIsBookMakerOpen("user")}
                  >
                    User Book
                  </a>
                </li>
                <li class="form-group active-button m-l-20 d-inline-block">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-back active"
                    id="modal-btn_userbookmaker"
                    onClick={() => setIsBookMakerOpen("book")}
                  >
                    Bookmarkers Book
                  </a>
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div class="coupon-card row">
          <div class="col-md-8 main-content">
            {matchDetails?.gameId && (
              <iframe
                style={{
                  width: "100%",
                }}
                src={`https://score.proexch.in/#/score1/${matchDetails?.gameId}`}
              />
            )}
            {matchDetails && eventId && (
              <div class="game-heading m-b-5">
                <span
                  class="card-header-title event_name_heading"
                  id="spn_event_title"
                >
                  {Object.keys(getCombinedExposureFromAll()).map((item) => (
                    <span>
                      {item} : {getCombinedExposureFromAll()[item]}{" "}
                    </span>
                  ))}
                </span>
              </div>
            )}
            <div class="game-heading m-b-5">
              <span
                class="card-header-title event_name_heading"
                id="spn_event_title"
                eventid={matchDetails?.eventId}
                marketid={matchDetails?.marketId}
                event_name={matchDetails?.eventName}
              >
                {matchDetails?.eventName} -&gt; MATCH_ODDS -&gt;
                {moment(matchDetails?.eventTime + "Z")
                  .utc()
                  .format("DD-MM-YYYY HH:mm A")}
              </span>
            </div>
            <div class="card-content">
              <div id="scoreboard-box"></div>
              <div id="match_odds_all_full_markets">
                <div
                  id="div_gamedata"
                  class="table-responsive m-b-10 main-market"
                >
                  <table
                    class="table coupon-table table table-bordered m-t-10 "
                    border="0"
                  >
                    <thead>
                      <tr>
                        <th>Match Odds</th> <th class="box-w1"></th>
                        <th class="box-w1"></th>
                        <th class="back box-w1">Back</th>
                        <th class="lay box-w1">lay</th> <th class="box-w1"></th>
                        <th class="box-w1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchOdds?.matchOdds[0]?.oddDetailsDTOS?.map((game) => (
                        <>
                          <tr
                            class={`bet-info sec-4297012 ${game?.status?.toLowerCase()}`}
                            data-title={game.status}
                            id="fullSelection_890529_MATCH_ODDS"
                            eventtype="4"
                            selectionid={game.selectionId}
                            eventname={game.runnerName}
                            status={game.status}
                          >
                            <td>
                              <span class="team-name">{game.runnerName}</span>
                              <p class="box-w4">
                                <span
                                  class="float-left profit live_match_points"
                                  id="live_match_points_890529_MATCH_ODDS"
                                  style={{
                                    color:
                                      getExposure(
                                        game,
                                        matchOdds?.matchOdds[0],
                                        "MATCH_ODDS"
                                      )?.exposure < 0
                                        ? "red"
                                        : "green",
                                  }}
                                >
                                  {
                                    getExposure(
                                      game,
                                      matchOdds?.matchOdds[0],
                                      "MATCH_ODDS"
                                    )?.exposure
                                  }
                                </span>
                              </p>
                            </td>
                            <td class="box-w1 back2">
                              <button
                                id="back_3_890529_MATCH_ODDS"
                                fullmarketodds="1.03"
                              >
                                <span class="odd d-block">{game?.back3}</span>
                                <span class="d-block">{game?.backSize3}</span>
                              </button>
                            </td>
                            <td class="box-w1 back1">
                              <button
                                id="back_2_890529_MATCH_ODDS"
                                fullmarketodds="1.04"
                              >
                                <span class="odd d-block">{game?.back2}</span>
                                <span class="d-block">{game?.backSize2}</span>
                              </button>
                            </td>
                            <td class="back box-w1">
                              <button
                                class="back"
                                id="back_1_890529_MATCH_ODDS"
                                fullmarketodds="1.05"
                              >
                                <span class="odd d-block">{game?.back1}</span>
                                <span class="d-block">{game?.backSize1}</span>
                              </button>
                            </td>
                            <td class="lay box-w1">
                              <button
                                class="lay"
                                id="lay_1_890529_MATCH_ODDS"
                                fullmarketodds="1.06"
                              >
                                <span class="odd d-block">{game?.lay1}</span>
                                <span class="d-block">{game?.laySize1}</span>
                              </button>
                            </td>
                            <td class="box-w1 lay1">
                              <button
                                id="lay_2_890529_MATCH_ODDS"
                                fullmarketodds="1.07"
                              >
                                <span class="odd d-block">{game?.lay2}</span>
                                <span class="d-block">{game?.laySize2}</span>
                              </button>
                            </td>
                            <td class="box-w1 lay2">
                              <button
                                id="lay_3_890529_MATCH_ODDS"
                                fullmarketodds="1.08"
                              >
                                <span class="odd d-block">{game?.lay3}</span>
                                <span class="d-block">{game?.laySize3}</span>
                              </button>
                            </td>
                          </tr>
                          <span
                            style={{
                              textAlign: "center",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                              color: "red",
                            }}
                          >
                            {game?.remark}
                          </span>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="bookmaker_markets"></div>
              <div>
                <div
                  class="row row5 bookmaker-container"
                  id="bookmaker_market_div"
                >
                  <div
                    class={`fancy-marker-title ${
                      matchOdds?.bookMakerOdds[0]?.bm2?.marketId
                        ? "col-8"
                        : "col-12"
                    }
                     `}
                    id="bm-head"
                  >
                    <h4>Bookmaker Market 0% commission fast bet confirm</h4>
                    <div class="m-b-10 bookmaker-market">
                      <div id="bm-section">
                        <table
                          class="table coupon-table table table-bordered m-t-10 "
                          border="0"
                        >
                          <thead>
                            <tr>
                              <th>
                                <span class="text-info p-r-5">
                                  Min:<span id="bookmaker_min">100</span>
                                </span>
                                <span class="text-info">
                                  Max:<span id="bookmaker_max">100000</span>
                                </span>
                              </th>
                              <th class="box-w1"></th>
                              <th class="box-w1"></th>
                              <th class="back box-w1">Back</th>
                              <th class="lay box-w1">lay</th>
                              <th class="box-w1"></th>
                              <th class="box-w1"></th>
                            </tr>
                          </thead>
                          <tbody id="match_odds_bookmaker_market">
                            {matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS?.map(
                              (game) => (
                                <>
                                  <tr
                                    class={`bet-info sec-1 table-row ${game?.status?.toLowerCase()}`}
                                    id="bookmaker_row_England_Women"
                                    data-title={game?.status}
                                  >
                                    <td>
                                      <span class="team-name">
                                        {game?.runnerName}
                                      </span>
                                      <p class="box-w4">
                                        <span
                                          style={{
                                            color:
                                              getExposure(
                                                game,
                                                matchOdds?.bookMakerOdds[0]
                                                  ?.bm1,
                                                "MATCH_ODDS"
                                              )?.exposure < 0
                                                ? "red"
                                                : "green",
                                          }}
                                          class="float-left profit"
                                          id="live_match_points_England_Women_BOOKMAKER_ODDS"
                                        >
                                          {
                                            getExposure(
                                              game,
                                              matchOdds?.bookMakerOdds[0]?.bm1,
                                              "MATCH_ODDS"
                                            )?.exposure
                                          }
                                        </span>
                                      </p>
                                    </td>
                                    <td
                                      class="back2 box-w1"
                                      id="back_3_England_Women_BOOKMAKER_ODDS"
                                      fullmarketodds=""
                                    >
                                      <span class="odd d-block"></span>
                                      <span class="d-block runner-size"></span>
                                    </td>
                                    <td
                                      class="back1 box-w1"
                                      id="back_2_England_Women_BOOKMAKER_ODDS"
                                      fullmarketodds=""
                                    >
                                      <span class="odd d-block"></span>
                                      <span class="d-block runner-size"></span>
                                    </td>
                                    <td
                                      class="back box-w1"
                                      id="back_1_England_Women_BOOKMAKER_ODDS"
                                      fullmarketodds="5.75"
                                    >
                                      <span class="odd d-block">
                                        {game?.back1}
                                      </span>
                                      <span class="d-block runner-size">
                                        {game?.backSize1}
                                      </span>
                                    </td>
                                    <td
                                      class="lay box-w1"
                                      id="lay_1_England_Women_BOOKMAKER_ODDS"
                                      fullmarketodds="6.75"
                                    >
                                      <span class="odd d-block">
                                        {game?.lay1}
                                      </span>
                                      <span class="d-block runner-size">
                                        {game?.laySize1}
                                      </span>
                                    </td>
                                    <td
                                      class="lay1 box-w1"
                                      id="lay_2_England_Women_BOOKMAKER_ODDS"
                                      fullmarketodds=""
                                    >
                                      <span class="odd d-block"></span>
                                      <span class="d-block runner-size"></span>
                                    </td>
                                    <td
                                      class="lay2 box-w1"
                                      id="lay_3_England_Women_BOOKMAKER_ODDS"
                                      fullmarketodds=""
                                    >
                                      <span class="odd d-block"></span>
                                      <span class="d-block runner-size"></span>
                                    </td>
                                  </tr>
                                  <span
                                    style={{
                                      textAlign: "center",
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      color: "red",
                                    }}
                                  >
                                    {game?.remark}
                                  </span>
                                </>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    class="fancy-marker-title col-xl-4"
                    id="bm1-market_div"
                    style={{
                      display: matchOdds?.bookMakerOdds[0]?.bm2?.marketId
                        ? "block"
                        : "none",
                    }}
                  >
                    <h4>Bookmaker Market</h4>
                    <div class="m-b-10 bookmaker-market">
                      <div id="bm1-section">
                        <table
                          class="table coupon-table table table-bordered m-t-10 "
                          border="0"
                        >
                          <thead>
                            <tr>
                              <th>
                                <span class="text-info p-r-5">Min:100</span>
                                <span class="text-info">Max:25000</span>
                              </th>
                              <th class="back box-w1">Back</th>
                              <th class="lay box-w1">lay</th>
                            </tr>
                          </thead>
                          <tbody id="match_odds_bookmakersmall_market">
                            {matchOdds?.bookMakerOdds[0]?.bm2?.oddDetailsDTOS?.map(
                              (game) => (
                                <tr
                                  class="bet-info sec-1 table-row"
                                  id="bookmakersmall_row_Lahore_Qalandars"
                                  data-title={game?.status}
                                >
                                  <td>
                                    <span class="team-name">
                                      {game?.runnerName}
                                    </span>
                                    <p class="box-w4">
                                      <span
                                        style={{
                                          color:
                                            getExposure(
                                              game,
                                              matchOdds?.bookMakerOdds[0]?.bm2,
                                              "BOOKMAKER_ODDS"
                                            )?.exposure < 0
                                              ? "red"
                                              : "green",
                                        }}
                                        class="float-left profit"
                                        id="live_match_points_Lahore_Qalandars_BOOKMAKERSMALL_ODDS"
                                      >
                                        {
                                          getExposure(
                                            game,
                                            matchOdds?.bookMakerOdds[0]?.bm2,
                                            "BOOKMAKER_ODDS"
                                          )?.exposure
                                        }
                                      </span>
                                    </p>
                                  </td>
                                  <td
                                    class="back box-w1"
                                    id="back_1_Lahore_Qalandars_BOOKMAKERSMALL_ODDS"
                                    fullmarketodds="11"
                                  >
                                    <span class="odd d-block">
                                      {game?.back1}
                                    </span>
                                    <span class="d-block runner-size">
                                      {game?.backSize1}
                                    </span>
                                  </td>
                                  <td
                                    class="lay box-w1"
                                    id="lay_1_Lahore_Qalandars_BOOKMAKERSMALL_ODDS"
                                    fullmarketodds="11.75"
                                  >
                                    <span class="odd d-block">
                                      {game?.lay1}
                                    </span>
                                    <span class="d-block runner-size">
                                      {game?.laySize1}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                {matchOdds?.fancyOdds?.map((match) => (
                  <div class="col-md-6 fancy-market" id="fancy-market">
                    <div class="fancy-marker-title">
                      <h4>{match?.gameType}</h4>
                    </div>
                    <div id="div_fancy">
                      <table class="table coupon-table table table-bordered m-t-10 ">
                        <thead>
                          <tr>
                            <th></th>
                            <th class="text-center box-w1 lay">No</th>
                            <th class="text-center back box-w1">Yes</th>
                            <th class="box-w2"></th>
                          </tr>
                        </thead>
                        <tbody id="fancy_odds_market_tbody"></tbody>
                        {match?.oddDetailsDTOS?.map((game) => (
                          <>
                            {" "}
                            <tr
                              data-title={game?.status}
                              class={`bet-info sec-3  ${game?.status?.toLowerCase()}`}
                              id="fancyBetMarket_32116272_1"
                            >
                              <td>
                                <a
                                  onClick={() => setShowMarket(game.marketId)}
                                  class="modal-book"
                                  data-mid="undefined"
                                  data-sid="3"
                                  data-gametype="Fancy"
                                  data-run="6"
                                >
                                  {game?.runnerName}
                                </a>
                                <p
                                  class="client"
                                  id="live_match_points_1_FANCY_ODDS"
                                  style={{
                                    color:
                                      getExposure(game, match, "FANCY")
                                        ?.exposure < 0
                                        ? "red"
                                        : "green",
                                  }}
                                >
                                  {getExposure(game, match, "FANCY")?.exposure}
                                </p>
                              </td>
                              <td class="lay box-w1">
                                <button class="lay" id="fancy_market_lay_btn_1">
                                  <span class="odd">
                                    {checkIfFloat(game?.lay1)}
                                  </span>
                                  <span>{checkIfFloat(game?.laySize1)}</span>
                                </button>
                              </td>
                              <td class="back box-w1">
                                <button
                                  class="back"
                                  id="fancy_market_back_btn_1"
                                >
                                  <span class="odd">
                                    {checkIfFloat(game?.back1)}
                                  </span>
                                  <span>{checkIfFloat(game?.backSize1)}</span>
                                </button>
                              </td>
                              <td class="text-right p-r-10 box-w2">
                                <span class="d-block text-info">Min:100</span>
                                <span class="d-block text-info">
                                  Max:100000
                                </span>
                              </td>
                            </tr>
                            <span
                              style={{
                                textAlign: "center",
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                color: "red",
                              }}
                            >
                              {game?.remark}
                            </span>
                          </>
                        ))}
                      </table>
                    </div>
                  </div>
                ))}
              </div>

              <div class="fancy-market row row5">
                <div class="col-6"></div>
              </div>
            </div>
          </div>
          <div class="col-md-4 sidebar-right" id="sidebar-right">
            <div class="card m-b-10">
              <div
                class="card-header"
                data-toggle="collapse"
                data-target=".video-tv"
                id="showtv"
                onClick={() => setIsLiveTvOpen(!isLiveTvOpen)}
              >
                <h6 class="card-title">
                  Live Match
                  <span class="float-right">
                    <i class="fa fa-tv"></i> live stream started
                  </span>
                </h6>
              </div>
              <div
                id="video-tv"
                class={`video-tv ${isLiveTvOpen ? "" : "collapse"} `}
              >
                <div
                  align="center"
                  class="tv-container"
                  style={{ marginTop: "2px", marginBottom: "5px" }}
                >
                  {channelId && (
                    <iframe
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      src={`https://video.proexch.in/tv/static?chid=${channelId}`}
                    />
                  )}
                </div>
              </div>
            </div>
            {/*  <div class="card m-b-10" id="scoreboard-main">
              <div class="card-header">
                <h6 class="card-title">Score Card</h6>
              </div>
              <div
                class="card-body scoreboard-detail collapse"
                id="scoreboard-box"
                style={{ display: "block" }}
              >
                <div class="scorecard m-b-5">
                  <div class="row">
                    <span class="team-name col-md-2">WAR</span>
                    <span class="score col-md-6"></span>
                    <span class="col-md-2 run-rate"></span>
                    <span class="col-md-2 run-rate"></span>
                  </div>
                  <div class="row m-t-10">
                    <span class="team-name col-md-2">CC</span>
                    <span class="score col-md-6">130-3 (30.4)</span>
                    <span class="col-md-2 run-rate">CRR 4.24</span>
                    <span class="col-md-2 run-rate"></span>
                  </div>
                  <div class="row">
                    <div class="col-md-6 m-t-10"></div>
                    <div class="col-md-6 ball-runs-container m-t-5">
                      <p class="text-right ball-by-ball">
                        <span class="ball-runs ">w</span>
                        <span class="ball-runs ">0</span>
                        <span class="ball-runs ">1</span>
                        <span class="ball-runs ">0</span>
                        <span class="ball-runs ">1</span>
                        <span class="ball-runs ">0</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="card m-b-10 my-bet">
              <div class="card-header">
                <ul class="nav nav-tabs d-inline-block" role="tablist ">
                  <li class="nav-item d-inline-block">
                    <a
                      class={`nav-link ${
                        betStatus === "MATCHED" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      onClick={() => setBetStatus("MATCHED")}
                    >
                      Matched
                    </a>
                  </li>
                  <li class="nav-item d-inline-block">
                    <a
                      class={`nav-link ${
                        betStatus === "UNMATCHED" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      onClick={() => setBetStatus("UNMATCHED")}
                    >
                      Unmatched
                    </a>
                  </li>
                </ul>
                <a
                  href="javascript:void(0)"
                  id="view_more_bets"
                  class="btn btn-back float-right"
                  onClick={() => setIsViewMoreBetModalOpen(true)}
                >
                  View More
                </a>
              </div>
              <div class="card-body">
                <div class="tab-content">
                  <div id="matched-bet" class="tab-pane active">
                    <div class="table-responsive">
                      <table
                        id="matched"
                        class="table coupon-table table-bordered table-stripted"
                      >
                        <thead>
                          <tr>
                            <th style={{ minWidth: "50px" }}>UserName</th>
                            <th style={{ minWidth: "200px" }}>Nation</th>
                            <th style={{ minWidth: "50px" }}>Rate</th>
                            <th style={{ minWidth: "70px" }}>Amount</th>
                            <th>PlaceDate</th>
                            <th>MatchDate</th>
                            <th style={{ minWidth: "70px" }}>Gametype</th>
                          </tr>
                        </thead>
                        <tbody id="tbody-active_bets">
                          {clientBets?.length > 0 &&
                            clientBets?.map((bet) => (
                              <tr
                                style={{
                                  borderLeft: `5px solid ${
                                    bet?.back
                                      ? "rgba(114, 187, 239, 1)"
                                      : "rgba(249, 148, 186, 1)"
                                  } `,
                                  backgroundColor: `${
                                    bet?.back
                                      ? "rgba(114, 187, 239, 1)"
                                      : "rgba(249, 148, 186, 1)"
                                  } `,
                                }}
                                colspan="100%"
                              >
                                <td>{bet?.userName}</td>
                                <td>{bet?.runnerName}</td>
                                <td>{bet?.rate}</td>
                                <td>{bet?.amount}</td>
                                <td>
                                  {moment(bet?.placeDate + "Z")
                                    .utc()
                                    .local()
                                    .format("DD/MM/YY hh:mm:ss A")}
                                </td>
                                <td>
                                  {moment(bet?.placeDate).format("DD/MM/YY ")}
                                </td>
                                <td>{bet?.marketName}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div id="unmatched-bet" class="tab-pane">
                    <div class="table-responsive">
                      <table
                        id="unmatched"
                        class="table coupon-table table-bordered table-stripted"
                      >
                        <thead>
                          <tr>
                            <th>UserName</th>
                            <th style={{ minWidth: "200px" }}>Nation</th>
                            <th style={{ minWidth: "50px" }}>Rate</th>
                            <th style={{ minWidth: "70px" }}>Amount</th>
                            <th>MatchDate</th>
                            <th>Gametype</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="100%" align="center">
                              No record found!...
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card m-b-10 my-bet">
              <div class="card-header">
                <ul class="nav nav-tabs d-inline-block" role="tablist ">
                  <li class="nav-item d-inline-block">
                    <a
                      class={`nav-link ${
                        selectedTab === "book" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      onClick={() => setSelectedTab("book")}
                    >
                      BookMaker Book
                    </a>
                  </li>
                  <li class="nav-item d-inline-block">
                    <a
                      class={`nav-link ${
                        selectedTab === "user" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      onClick={() => setSelectedTab("user")}
                    >
                      User Book
                    </a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content">
                  <div id="matched-bet" class="tab-pane active">
                    <div class="table-responsive">
                      <table
                        id="matched"
                        class="table coupon-table table-bordered table-stripted"
                      >
                        <thead>
                          <tr>
                            {userBook?.columns?.length > 0 &&
                              userBook?.columns?.map((book) => (
                                <th>{book?.name}</th>
                              ))}
                          </tr>
                        </thead>
                        <tbody id="tbody-active_bets">
                          {userBook?.rows?.map((bet) => (
                            <tr colspan="100%">
                              {userBook?.columns?.map((column) => (
                                <td
                                  style={{
                                    color:
                                      bet[column?.id] < 0 ? "red" : "green",
                                  }}
                                >
                                  {bet[column?.id]}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div id="unmatched-bet" class="tab-pane">
                    <div class="table-responsive">
                      <table
                        id="unmatched"
                        class="table coupon-table table-bordered table-stripted"
                      >
                        <thead>
                          <tr>
                            <th>UserName</th>
                            <th style={{ minWidth: "200px" }}>Nation</th>
                            <th style={{ minWidth: "50px" }}>Rate</th>
                            <th style={{ minWidth: "70px" }}>Amount</th>
                            <th>MatchDate</th>
                            <th>Gametype</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="100%" align="center">
                              No record found!...
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
