import {
  Box,
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomDataModal from "../../../components/UI/CustomDataModal";
import CustomInput from "../../../components/UI/CustomInput";
import CustomSelect from "../../../components/UI/CustomSelect";
import CustomTable from "../../../components/UI/Table";
import {
  getClientBetsCricketAPI,
  handleBetAPI,
} from "../../../service/cricket";
import { useSelector } from "react-redux";
import { PERMISSION_FOR_DELETE_BET } from "../../../utils/constants";
import {
  getClientBetsSoccerAPI,
  getClientBetsTennisAPI,
  handleBetSoccerAPI,
  handleBetTennisAPI,
} from "../../../service/soccer&tennis";
import toast from "react-hot-toast";

export default function ViewMoreBet({ isOpen, onClose, gameType, eventId }) {
  const user = useSelector((state) => state.account.userData);
  const [columns, setColumns] = React.useState([
    { name: "UserName", id: "userName" },
    { name: "Nation", id: "runnerName" },
    { name: "Bet Type", id: "marketName" },
    { name: "Amount", id: "amount" },
    { name: "User Rate", id: "rate" },
    {
      name: "Place Date",
      id: "userName",
      element: (row) => (
        <td>
          <Text>{new Date(row?.placeDate).toLocaleString()}</Text>
        </td>
      ),
    },
    {
      name: "Type",
      id: "isBack",
      element: (row) => <td>{row?.back ? "Back" : "Lay"}</td>,
    },

    { name: "IP", id: "ipAddress" },
    {
      name: "Match Date",
      id: "userName",
      element: (row) => (
        <td>
          <Text>{new Date(row?.placeDate).toLocaleString()}</Text>
        </td>
      ),
    },
    {
      name: "Device",
      id: "userAgent",
    },
    {
      name: "Browser Details",
      id: "browserDetails",
      element: (row) => (
        <td>
          <Popover preventOverflow={true}>
            <PopoverTrigger>
              <Text _hover={{ cursor: "pointer" }} color="blue.500">
                Show
              </Text>
            </PopoverTrigger>
            <PopoverContent>{row?.browserDetails}</PopoverContent>
          </Popover>
        </td>
      ),
    },
    {
      name: "Action",
      id: "action",
      element: (row) => (
        <td
          style={{
            display: "flex",
          }}
        >
          {PERMISSION_FOR_DELETE_BET.includes(
            user?.userType?.toLowerCase()
          ) && (
            <>
              <Button
                onClick={() => handleBet(row, "DELETED")}
                mr="1"
                colorScheme="red"
                size="sm"
              >
                Delete
              </Button>
              <Button
                onClick={() => handleBet(row, "UNMATCHED")}
                colorScheme="blue"
                size="sm"
              >
                Un-Match
              </Button>
            </>
          )}
        </td>
      ),
    },
  ]);
  const [rows, setRows] = React.useState([]);
  const [betStatus, setBetStatus] = React.useState("MATCHED");
  const [clientName, setClientName] = React.useState("");
  const [ipAddress, setIpAddress] = React.useState("");
  const [betAmount, setBetAmount] = React.useState("");
  const [betType, setBetType] = React.useState("");
  const getClientBets = async (betStatus_) => {
    if (gameType == "cricket") {
      setRows([]);
      const { response } = await getClientBetsCricketAPI(
        eventId,
        betStatus_ ? betStatus_ : betStatus
      );
      if (response) {
        setRows(response);
      }
    } else if (gameType == "tennis") {
      setRows([]);
      const { response } = await getClientBetsTennisAPI(
        eventId,
        betStatus_ ? betStatus_ : betStatus
      );
      if (response) {
        setRows(response);
      }
    } else {
      setRows([]);
      const { response } = await getClientBetsSoccerAPI(
        eventId,
        betStatus_ ? betStatus_ : betStatus
      );
      if (response) {
        setRows(response);
      }
    }
  };
  const handleBet = async (data, action = "DELETED") => {
    if (gameType == "cricket") {
      const { response } = await handleBetAPI(data.id, action);
      if (response) {
        toast.success(response);
        getClientBets();
      } else {
        toast.error("Error");
      }
    } else if (gameType == "tennis") {
      const { response } = await handleBetTennisAPI(data.id, action);
      if (response) {
        toast.success(response);
        getClientBets();
      }
    } else if (gameType == "soccer") {
      const { response } = await handleBetSoccerAPI(data.id, action);
      if (response) {
        toast.success(response);
        getClientBets();
      }
    }
  };
  const search = () => {
    let rows_ = rows;
    if (clientName) {
      rows_ = rows_.filter((row) => row.userName.includes(clientName));
    }
    if (ipAddress) {
      rows_ = rows_.filter((row) => row.ipAddress.includes(ipAddress));
    }
    if (betAmount) {
      rows_ = rows_.filter((row) => row.amount === betAmount);
    }
    if (betType) {
      rows_ = rows_.filter((row) => row.betType.includes(betType));
    }
    setRows(rows_);
  };
  useEffect(() => {
    getClientBets();
  }, [betStatus]);
  return (
    <CustomDataModal
      size="7xl"
      header={"View More Bet"}
      onClose={onClose}
      isOpen={isOpen}
    >
      <Tabs>
        <TabList>
          <Tab
            onClick={() => {
              setBetStatus("MATCHED");
              getClientBets();
            }}
          >
            Matched Bets
          </Tab>
          <Tab
            onClick={() => {
              setBetStatus("UNMATCHED");
              getClientBets();
            }}
          >
            Unmatched Bets
          </Tab>
          <Tab
            onClick={() => {
              setBetStatus("DELETED");
              getClientBets();
            }}
          >
            Deleted Bets
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box>
              <WrapItem
                mb="2"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <CustomInput
                  onChange={(e) => {
                    setClientName(e.target.value);
                  }}
                  label="Client"
                  placeholder="All Client"
                />
                <CustomInput
                  onChange={(e) => {
                    setIpAddress(e.target.value);
                  }}
                  label="IP Address"
                />
                <CustomInput
                  onChange={(e) => {
                    setBetAmount(e.target.value);
                  }}
                  label="Bet Amount"
                />
                <CustomSelect
                  m="1"
                  label="Type"
                  onChange={(e) => {
                    setBetType(e.target.value);
                  }}
                  options={[
                    { label: "All", value: "" },
                    { label: "Back", value: "back" },
                    { label: "Lay", value: "lay" },
                  ]}
                />
                <Button onClick={search}>Search</Button>
                {/*   <Button disabled>View All</Button> */}
              </WrapItem>
            </Box>
            <CustomTable rowColored={true} columns={columns} rows={rows} />
          </TabPanel>
          <TabPanel>
            <CustomTable rowColored={true} columns={columns} rows={rows} />
          </TabPanel>
          <TabPanel>
            <CustomTable rowColored={true} columns={columns} rows={rows} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </CustomDataModal>
  );
}
